import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
export default function HeaderTop() {
  return (
    <div className="sub-navigation">
      <div className="container-lg">
        <nav className="sub-nav">
          <ul className="sub-list col-md-12 row">
            <li className="sub-list_item col-sm-12 col-md-6">
              <FontAwesomeIcon icon={faPhone} className="fa" />
              <span className="s-l_item-label"> Telefon:</span>
              <a href="tel:0176 29468329">0176 29468329</a>
            </li>
            <li className="sub-list_item col-sm-12 col-md-6">
              <FontAwesomeIcon icon={faEnvelope} className="fa" />
              <span className="s-l_item-label">E-Mail:</span>
              <a href="mailto:kontakt@hr-sanierung.de">
                kontakt@hr-sanierung.de
              </a>
            </li>
            <li className="sub-list_item col-sm-12 col-md-6">
              {/* <i class="fa fa-clock"></i><span class="s-l_item-label">Öffnungszeiten:</span>
            08:00 -17:00 Uhr */}
              <FontAwesomeIcon icon={faClock} className="fa" />{" "}
              24-Stunden-Notruf!
            </li>
            <li className="sub-list_item col-sm-12 col-md-6">
              <div className="s-l_item-social">
                <a
                  href="https://www.facebook.com/hrsanierung/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebookSquare} className="fa" />
                </a>
                <a
                  href="https://www.instagram.com/hr_sanierung/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} className="fa" />
                </a>
              </div>
            </li>
          </ul>
        </nav>
        <nav className="sub-nav_mobile">
          <ul className="sub-list">
            <li className="sub-list_item">
              <a href="mailto:kontakt@hr-sanierung.de">
                <FontAwesomeIcon icon={faEnvelope} className="fa" />
              </a>
            </li>
            <li className="sub-list_item">
              <a href="tel:0176 29468329">
                <FontAwesomeIcon icon={faPhone} className="fa" />
              </a>
            </li>
            <li className="sub-list_item">
              <a
                href="https://www.facebook.com/hrsanierung"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookSquare} className="fa" />
              </a>
            </li>
            <li className="sub-list_item">
              <a
                href="https://www.instagram.com/hr_sanierung"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} className="fa" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
