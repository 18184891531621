import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import favicon from "../images/favicon.png";

function SEO({ title, description, url }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            titleTemplate
            description
            keywords
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaKeywords = site.siteMetadata.keywords;
  const metaUrl = site.siteMetadata.siteUrl + url;

  return (
    <Helmet titleTemplate={site.siteMetadata.titleTemplate}>
      <html lang="de" />
      <meta charSet="utf-8" />
      <title>{title}</title>
      <link rel="icon" href={favicon} />
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords.join(",")} />
      <meta property="og:url" content={metaUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <script async defer data-website-id="d866517a-ef03-4f49-85a4-25194a2ad83e" src="https://loop.maltekoblitz.de/script.js"></script>
    </Helmet>
  );
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  url: PropTypes.string,
};

SEO.defaultProps = {
  title: "",
  description: "",
  url: "",
};

export default SEO;
