import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import logoWhite from "../images/logo_white.svg";

export default function Footer() {
  const { site } = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `);
  return (
    <section id="footer">
      <div className="footer-container section-wrapper">
        <div className="footer_item">
          <img
            className="footer_logo"
            width={175}
            src={logoWhite}
            alt="HR-Sanierung Logo"
          />
          <div className="footer_social">
            <a
              href="https://www.facebook.com/hrsanierung"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faFacebookSquare} className="fa" />
            </a>
            <a
              href="https://www.instagram.com/hr_sanierung"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} className="fa" />
            </a>
          </div>
        </div>
        <div className="footer_item">
          <h3>Kontakt</h3>
          <p>HR-Sanierung GmbH</p>
          <p>
            Berliner Straße 8<br />
            38226 Salzgitter
            <br />
          </p>
          <br />
          <p>
            Telefon: <a href="tel:+49 176 29468329">0176 29468329</a>
            <br />
            E-Mail:
            <a href="mailto:kontakt@hr-sanierung.de">kontakt@hr-sanierung.de</a>
          </p>
        </div>
        <div className="footer_item">
          <h3>Unternehmen</h3>
          <ul>
            {site.siteMetadata.menuLinks.map((link) => (
              <li className="main-item-lvl1" key={link.name}>
                <Link
                  className="main-link-lvl1"
                  title={link.name}
                  to={link.link}
                  activeClassName="active"
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="footer_item">
          <h3>Rechtliches</h3>
          <ul>
            <li>
              <Link to="/impressum">Impressum</Link>
            </li>
            <li>
              <Link to="/datenschutz">Datenschutz</Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
