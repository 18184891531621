import { Link } from "gatsby";
import React from "react";
import Slider from "react-slick";
export default function SliderMain({ isLanding }) {
  var settings = {
    arrows: false,
    // autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    fade: true,
  };
  return (
    <section id="slider" className={isLanding ? null : "content-page"}>
      <Slider {...settings}>
        <div className="slider-container">
          <div className="slider_item" id="slide-1">
            <div className="slider_item-container section-wrapper">
              <div className="slider_item-body col-xx-8">
                <h2>Brand- oder Wasserschaden</h2>
                <p>
                Sie suchen nach einem Industrieservice mit bundesweiten Einsatzmöglichkeiten? 
                Mit uns haben Sie einen starken Partner gefunden. Wir sind nicht nur rund um unseren Firmenstandort für Sie da, sondern auch bundesweit im Einsatz, um Ihnen zu helfen.
                </p>
                <Link to="/leistungen" className="btn">
                  <span className="btn-text">Mehr Erfahren</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </section>
  );
}
