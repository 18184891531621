import React from "react";
import "../style/sass/index.scss";

import Header from "../components/Header";
import Footer from "../components/Footer";
import SliderMain from "../components/Slider";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Layout({ landing, children }) {
  return (
    <>
      <Header />
      <SliderMain isLanding={landing} />
      {children}
      <Footer />
    </>
  );
}
