import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import HeaderTop from "./HeaderTop";

import logo from "../images/logo.svg";

export default function Header() {
  const [burger, toggleBurger] = useState(false);

  const { site } = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `);

  const bugermenu = () => {
    toggleBurger(!burger);
  };

  return (
    <>
      <HeaderTop />
      <header>
        <div className="header-container">
          <div className="container-lg">
            <div className="header-inner">
              <div className="header">
                <Link to="/" title="HR-Sanierung" className="header-hrs-link">
                  <img
                    src={logo}
                    width="200px"
                    alt="HR-Sanierung"
                    className="header-hrs-logo"
                  />
                </Link>
                <button
                  onClick={bugermenu}
                  id="mobile-menu"
                  className={burger ? "open" : null}
                >
                  <div aria-label="menu" className="hamburger"></div>
                </button>
              </div>
              <div className={`main-navigation ${burger ? null : "hide"}`}>
                <ul className="main-list-lvl1">
                  {site.siteMetadata.menuLinks.map((link) => (
                    <li className="main-item-lvl1" key={link.name}>
                      <Link
                        className="main-link-lvl1"
                        title={link.name}
                        to={link.link}
                        activeClassName="active"
                      >
                        {link.name}
                      </Link>
                    </li>
                  ))}
                  <li className="main-item-lvl1 main-item-cta">
                    <Link
                      to="/kontakt#kontaktform"
                      className="main-link-lvl1"
                      title="Angebot anfragen"
                    >
                      Angebot anfragen
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="make-offer_mobile">
          <Link
            to="/kontakt#kontaktform"
            title="Angebot anfragen"
            className="btn btn-accent"
          >
            <span className="btn-text">Angebot anfragen</span>
          </Link>
        </div>
      </header>
    </>
  );
}
