import React, { useState } from "react";
import { navigate } from "gatsby";

export default function Formular({ landing }) {
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telefon, setTelefon] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");
    setSubmit(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ name, email, telefon, message }),
    };
    fetch("/api/form/index.php", requestOptions).then(async (response) => {
      const data = await response.json();
      if (!data.sent === true) {
        setError(data.message);
        setSubmit(false);
      }
      navigate("/danke");
      resetFormular();
      return null;
    });
  };

  const resetFormular = () => {
    setSubmit(false);
    setName("");
    setEmail("");
    setTelefon("");
    setMessage("");
  };

  const content = (
    <div
      id={!landing ? "kontaktform" : "contact-form"}
      className={!landing ? "kontaktformular" : ""}
    >
      <h3>Kontaktieren Sie uns</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-container">
          <div className="form-left form-part">
            <input
              className="form-element"
              required
              type="text"
              name="name"
              placeholder="Name *"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              className="form-element"
              required
              type="email"
              name="email"
              placeholder="E-Mail *"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className="form-element"
              type="tel"
              name="telefon"
              placeholder="Telefonnummer"
              value={telefon}
              onChange={(e) => setTelefon(e.target.value)}
            />
          </div>
          <div className="form-right form-part">
            <textarea
              className="form-element"
              required
              name="message"
              placeholder="Nachricht *"
              rows={5}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        </div>
        <div className="form-extra margin">
          <input
            type="checkbox"
            required
            id="datenschutzCheckbox"
            name="datenschutz"
            defaultValue="datenschutz"
          />
          <label htmlFor="datenschutzCheckbox">
            Ja, ich bin damit einverstanden, dass die hier eingegebenen Daten
            maschinell gespeichert und verarbeitet werden können (Hinweis lt.
            Bundes-Datenschutzgesetz). Die{" "}
            <a href="/datenschutz">Datenschutzerklärung</a> habe ich gelesen.
          </label>
        </div>
        {error && <p className="error">{error}</p>}
        <button
          type="submit"
          disabled={submit}
          className={`btn ${submit ? "disable" : ""}`}
        >
          <span className="btn-text">{submit ? "Lädt" : "Senden"}</span>
        </button>
        <div className="form-extra">
          <span>Mit * markierte Felder müssen ausgefüllt werden.</span>
        </div>
      </form>
    </div>
  );

  return content;
}
